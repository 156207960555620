<template>
    <div  class ="important_image_edit">
        <div v-if="visible" v-loading="loading" element-loading-text="加载页面中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(f, f, f, 0.8)" style="float:left" >
            <div class="box-card-tip">
              <div>
                <i class="iconfont iconicon-13" ></i>
                <span>您本次上传了{{ total }}张发票，其中有{{needCheckCount}}张图片文件需要您再次确认解析内容</span>
              </div>
              <el-button type="primary" class="download" @click="confirmImport" :disabled="!disabledForward" >确认导入</el-button>
            </div>
            <div class="contain_left">
              <!-- 左边发票 -->
              <div   class="box-card-left ">
                <div class="compNav">
                  <div class="compNavIcon"></div>
                  <span>发票确认:{{currentPageCount}}/{{ needCheckCount }}</span>
                </div>
                <div class="control">
                  <el-button type="primary" class="download" @click="deleteInvoiceHandle" >删除本张发票</el-button>
                  <div>
                    <!-- 翻页按钮 -->
                    <el-button class="el-icon-arrow-left f24" @click="backwards" :disabled="disabledackwards"></el-button>
                    <el-button class="el-icon-arrow-right f24" @click="forward" :disabled="disabledForward"></el-button>
                  </div>
                </div>
                <div class="invoiceImgWrap">
                  <!-- 基础部分 -->
                  <div class="placeholderBox" v-loading='isdeling'  >
                  <!-- <div class="placeholderBox" v-loading='isdeling' element-loading-text="图片加载中..." element-loading-spinner="el-icon-loading" > -->
                    <img :src="invoiceImageUrl" style="width:100%" alt=""  @load='isImgOnload' >
                  </div>
                </div>
              </div>
            </div>
            <div class="contain_right">
              <!-- 右边信息表 -->
              <div  class="box-card-right ">
                <div class="compNav">
                  <div class="compNavIcon"></div>
                  <span>发票确认</span>
                </div>
                <div style="width:100%;">
                  <el-form :inline="true" :model="invoiceInfo" :rules="invoiceInfoRules" :label-position="labelPosition" ref="invoiceInfo" class="form-inline tz" style="width: 100%" label-width="34%">
                    <!-- 基本信息 -->
                    <el-row style="width: 100%">
                      <el-col :span="14">
                        <!-- 需交验 -->
                        <el-form-item label="票头" label-width="41%" prop="invHeader" style="width: 98%" class="double_query_b" >
                          <el-input placeholder="请输入票头" v-model="invoiceInfo.invHeader" type="input" :autosize="{ minRows: 1, maxRows: 3}"  maxlength="20" class="filter-item textarea" style="width: 100%"    />
                        </el-form-item>
                      </el-col>
                      <el-col :span="10">
                        <!-- 需交验 -->
                        <el-form-item label="发票代码" label-width="35%" prop="invCode" style="width: 98%" class="double_query">
                          <el-input placeholder="请输入发票代码" v-model="invoiceInfo.invCode" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="12"  class="filter-item textarea" style="width: 100%" />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row style="width: 100%">
                      <el-col :span="14">
                        <!-- 需交验 -->
                        <el-form-item label="发票号码" label-width="41%" prop="invNumber" style="width: 98%" class="double_query_b" >
                          <el-input placeholder="请输入发票号码" v-model="invoiceInfo.invNumber" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="8" class="filter-item textarea" style="width: 100%" />
                        </el-form-item>
                      </el-col>
                      <el-col :span="10">
                        <!-- 需交验 -->
                        <el-form-item label="开票日期" label-width="35%" prop="doInvDate" style="width: 98%" class="double_query">
                          <el-input placeholder="请输入开票日期" v-model="invoiceInfo.doInvDate" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="16" class="filter-item textarea" style="width: 100%" />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row style="width: 100%">
                      <el-col :span="14">
                        <el-form-item label="校验码" label-width="41%" prop="checkCode" style="width: 98%" class="double_query_b" >
                          <label slot="label">校验码</label>
                          <el-input placeholder="请输入校验码" v-model="invoiceInfo.checkCode" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="32" class="filter-item textarea" style="width: 100%" />
                        </el-form-item>
                      </el-col>
                      <el-col :span="10">
                        <el-form-item prop="machineCode" label-width="35%" style="width: 98%" class="double_query">
                          <label slot="label">&nbsp;&nbsp;&nbsp;机器编码</label>
                          <el-input placeholder="请输入机器编码" v-model="invoiceInfo.machineCode" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="12" class="filter-item textarea" style="width: 100%" />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 购买方信息 -->
                    <div class="compNavT"><strong>购买方信息：</strong></div>
                    <!-- 需交验 -->
                    <el-form-item label="名称" prop="buyerName" style="width: 100%" label-width="23%" class="single_query">
                      <el-input placeholder="请输入名称" v-model="invoiceInfo.buyerName" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="100" class="filter-item textarea" style="width: 100%" />
                    </el-form-item>
                    <el-form-item  prop="buyerTaxpayerNum" style="width: 100%" label-width="23%" class="single_query">
                      <label slot="label">&nbsp;&nbsp;&nbsp;纳税人识别码</label>
                      <el-input placeholder="请输入纳税人识别码" v-model="invoiceInfo.buyerTaxpayerNum" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="20" class="filter-item textarea" style="width: 100%" />
                    </el-form-item>
                    <el-form-item  prop="buyerPhoneNum" style="width: 100%" label-width="23%" class="single_query">
                      <label slot="label">&nbsp;&nbsp;&nbsp;地址/电话</label>
                      <el-input placeholder="请输入地址和电话" v-model="invoiceInfo.buyerPhoneNum" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" maxlength="100" class="filter-item textarea" style="width: 100%" />
                    </el-form-item>
                    <el-form-item label="开户行及账号" prop="buyerBankCardNum" style="width: 100%" label-width="23%" class="single_query">
                      <label slot="label">&nbsp;&nbsp;&nbsp;开户行及账号</label>
                      <el-input placeholder="请输入开户行及账号" v-model="invoiceInfo.buyerBankCardNum" type="textarea" :autosize="{ minRows: 1, maxRows: 3}" maxlength="100" class="filter-item textarea" style="width: 100%" />
                    </el-form-item>
                    <!-- 需交验 -->
                    <el-form-item label="密码区" prop="buyerPassword" style="width: 100%" label-width="23%" class="single_query">
                      <el-input placeholder="请输入密码区" v-model="invoiceInfo.buyerPassword" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" class=" textarea"  maxlength="180" style="width: 100%;" />
                    </el-form-item>
                    <!-- 销售方信息 -->
                    <div class="compNavT"><strong>销售方信息</strong></div>
                    <!-- 需校验 -->
                    <el-form-item label="名称" prop="sellName" style="width: 100%" label-width="23%" class="single_query">
                      <el-input placeholder="请输入名称" v-model="invoiceInfo.sellName" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="100" class="filter-item textarea" style="width: 100%" />
                    </el-form-item>
                    <!-- 需校验 -->
                    <el-form-item label="纳税人识别码" prop="sellTaxpayerNum" style="width: 100%" label-width="23%" class="single_query">
                      <el-input placeholder="请输入纳税人识别码" v-model="invoiceInfo.sellTaxpayerNum" type="textarea" :autosize="{ minRows: 1, maxRows: 3}" maxlength="20" class="filter-item textarea" style="width: 100%" />
                    </el-form-item>
                    <el-form-item  prop="sellPhoneNum" style="width: 100%" label-width="23%" class="single_query">
                      <label slot="label">&nbsp;&nbsp;&nbsp;地址/电话</label>
                      <el-input placeholder="请输入地址和电话" v-model="invoiceInfo.sellPhoneNum" type="textarea" :autosize="{ minRows: 1, maxRows: 3}" maxlength="100" class="filter-item textarea" style="width: 100%" />
                    </el-form-item>
                    <el-form-item  prop="sellBankCardNum" style="width: 100%" label-width="23%" class="single_query">
                      <label slot="label">&nbsp;&nbsp;&nbsp;开户行及账号</label>
                      <el-input placeholder="请输入开户行及账号" v-model="invoiceInfo.sellBankCardNum" type="textarea" :autosize="{ minRows: 1, maxRows: 3}" maxlength="100" class="filter-item textarea" style="width: 100%" />
                    </el-form-item>
                    <el-form-item label="备注" prop="postscript" style="width: 100%" label-width="23%" class="single_query">
                      <label slot="label">&nbsp;&nbsp;&nbsp;备注</label>
                      <el-input placeholder="请输入备注" v-model="invoiceInfo.postscript" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" maxlength="230" class="filter-item textarea" style="width: 100%" />
                    </el-form-item>
                    <el-row style="width: 100%">
                      <el-col :span="14">
                        <el-form-item label="收款人" prop="payee" style="width: 98%" label-width="41%" class="double_query_b" >
                          <label slot="label">&nbsp;&nbsp;&nbsp;收款人</label>
                          <el-input placeholder="请输入收款人" v-model="invoiceInfo.payee" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="16" class="filter-item textarea" style="width: 100%" />
                        </el-form-item>
                      </el-col>
                      <el-col :span="10">
                        <el-form-item label="复核" prop="review" style="width: 98%" label-width="28%" class="double_query_c">
                          <label slot="label">&nbsp;&nbsp;&nbsp;复核</label>
                          <el-input placeholder="请输入复核" v-model="invoiceInfo.review" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="16" class="filter-item textarea" style="width: 100%" />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row style="width: 100%">
                      <el-col :span="14">
                        <el-form-item label="开票人" prop="drawer" style="width: 98%" label-width="41%" class="double_query_b" >
                          <label slot="label">&nbsp;&nbsp;&nbsp;开票人</label>
                          <el-input placeholder="请输入开票人" v-model="invoiceInfo.drawer" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="16" class="filter-item textarea" style="width: 100%" />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 项目表格 -->
                    <div class="projectFormWrap" id="projectFormWrap" >
                      <div class="projectForm">
                        <el-row class="projectFormTitle bt">
                          <div class="sideBlank"></div>
                          <el-col  :span="6"><i class="mustCheckout">*</i> 货物或应税劳务、服务名称</el-col>
                          <el-col :span="4">规格型号</el-col>
                          <el-col :span="2">单位</el-col>
                          <el-col :span="2">数量</el-col>
                          <el-col :span="2"> 单价</el-col>
                          <el-col :span="3"><i class="mustCheckout">*</i> 金额</el-col>
                          <el-col :span="2" class="taxRate"><i class="mustCheckout">*</i> 税率</el-col>
                          <el-col :span="3"><i class="mustCheckout">*</i> 税额</el-col>
                          <div class="sideBlank"></div>
                        </el-row>
                        <el-row class="projectFormTitle" v-for="(item, index) in invoiceInfo.projectForm" :key="index">
                          <div class="sideBlank">
                            <i class="iconfont iconjia" style="color:#35C4B1" @click="addProjectHandle"></i>
                          </div>
                          <!-- 需校验 -->
                          <el-col :span="6" class="colItem"><el-form-item :prop="'projectForm.'+index+'.productName'" :rules="invoiceInfoRules.productName" ><el-input placeholder="请输入相关名称" v-model="item.productName"  type="textarea" :autosize="{ minRows: 1, maxRows: 1}" maxlength="100" class="textarea" style="width: 94%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                          <el-col :span="4"><el-input placeholder="规格型号" v-model="item.productUnit" type="input" :autosize="{ minRows: 1, maxRows: 3}" maxlength="36" class="textarea" style="width: 90%" @keyup.enter.native="handleQuery"/></el-col>
                          <el-col :span="2"><el-input placeholder="单位" v-model="item.unitName"   maxlength="32"  style="width: 90%" @keyup.enter.native="handleQuery"/></el-col>
                          <!-- 需交验 -->
                          <el-col :span="2" class="colItem"><el-form-item :prop="'projectForm.'+index+'.count'"  :rules="invoiceInfoRules.count" ><el-input placeholder="数量" v-model="item.count" maxlength="16" style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                          <!-- 需交验 -->
                          <el-col :span="2" class="colItem"><el-form-item :prop="'projectForm.'+index+'.unitPrice'" :rules="invoiceInfoRules.unitPrice" ><el-input placeholder="单价" v-model="item.unitPrice" maxlength="16" style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                          <!-- 需交验 -->
                          <el-col :span="3" class="colItem"><el-form-item :prop="'projectForm.'+index+'.invoiceAmount'" :rules="invoiceInfoRules.invoiceAmount" ><el-input placeholder="金额" v-model="item.invoiceAmount" maxlength="16" style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                          <!-- 需交验 -->
                          <el-col :span="2" class="colItem taxRate"><el-form-item :prop="'projectForm.'+index+'.taxRate'" :rules="invoiceInfoRules.taxRate"  ><el-input placeholder="税率" v-model="item.taxRate" maxlength="8" style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                          <!-- 需交验 -->
                          <el-col :span="3" class="colItem"><el-form-item :prop="'projectForm.'+index+'.taxAmount'" :rules="invoiceInfoRules.taxAmount" ><el-input placeholder="税额" v-model="item.taxAmount" maxlength="16" style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                          <div class="sideBlank">
                            <i class="iconfont iconicon-12" style="color:#FF0000" @click="delProjectRow(index)"></i>
                          </div>
                        </el-row>
                        <el-row class="projectFormTitle">
                          <div class="sideBlank"></div>
                          <el-col :span="2">合计：</el-col>
                          <el-col :span="14"></el-col>
                          <el-col :span="3" class="colItem"><el-form-item  prop="totalMoney"  style="width: 98%" ><el-input placeholder="合计金额"  v-model="invoiceInfo.totalMoney" maxlength="16" style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                          <el-col :span="2"></el-col>
                          <el-col :span="3" class="colItem"><el-form-item  prop="totalTaxMoney"  style="width: 98%" ><el-input placeholder="合计税额" v-model="invoiceInfo.totalTaxMoney" maxlength="16" style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                          <div class="sideBlank"></div>
                        </el-row>
                        <el-row class="projectFormTitle">
                          <div class="sideBlank"></div>
                          <el-col :span="14" class="itemContent colItem"><el-form-item prop="totalPriceTaxDX" label="价税合计(大写):"  label-width="28%" style="width: 98%" ><el-input placeholder="价税合计大写" v-model="invoiceInfo.totalPriceTaxDX" maxlength="60" style="width: 100%"  @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                          <el-col :span="2"></el-col>
                          <el-col :span="8"  class="itemContent colItem lower"><el-form-item prop="totalPriceTax" label="小写:" label-width="24%" style="width: 98%" ><el-input placeholder="价税合计" v-model="invoiceInfo.totalPriceTax" maxlength="16" style="width: 100%"  @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                          <div class="sideBlank"></div>
                        </el-row>
                      </div>
                    </div>
                    <!-- <div class="tableCheckTip" v-if="tableCheckTip">*请完成列表中{{tableCheckTip}}字段的校验*</div> -->
                    <el-button type="primary" @click="submitForm('invoiceInfo')" ref="submitForm" style="display: none;"></el-button>
                  </el-form>
                </div>
              </div>
            </div>
        </div>
        <upload-list v-else ></upload-list>
    </div>
</template>

<script>
// import log from '../../../store/modules/recycleBin';
import uploadList from './components/uploadList'
import Cookies from 'js-cookie'
export default {
    name: 'WebIndex',
    components:{ uploadList },
    data() {
      // 收集未校验成功字段的集合
    const tableCheckTipHandle=(value)=>{
      if(this.tableCheckTip.search(value)==-1) {
        this.tableCheckTip = this.tableCheckTip +'"'+ value +'"'
      }
    }
    //校验成功就再集合中删除
    const deleteCheckTipHandle =(value)=>{
      if(this.tableCheckTip.search(value)!=-1) {
        this.tableCheckTip = this.tableCheckTip.replace(value,'')
      }
    }
    /* 票头校验 */
    const checkInvHeader = (rule, value, callback) => {
        if(value) {
          const Reg = /(普通发票|专用发票)$/;
          if(!Reg.test(value)){
            return callback( new Error("必须包含“普通发票”或“专用发票”字样") )
          }
        } else {
            return callback( new Error("请输入票头信息") )
          }
        callback()
      }
    /* 发票代码校验 */
    const checkInvCode = (rule, value, callback) => {
        if(value) {
          const Reg = /^\d{12}$/;
          if(!Reg.test(value)){
            return callback( new Error("发票代码为12位纯数字") )
          }
        } else {
            return callback( new Error("请输入发票代码") )
          }
        callback()
      }
    /* 发票号码校验 */
    const checkInvNumber = (rule, value, callback) => {
        if(value) {
          const Reg = /^\d{8}$/;
          if(!Reg.test(value)){
            return callback( new Error("发票代码为8位纯数字") )
          }
        } else {
            return callback( new Error("请输入发票号码") )
          }
        callback()
      }
    /* 开票日期校验 */
    const checkDoInvDate = (rule, value, callback) => {
        if(value) {
          const invDoInvDate = /^(\d{4})(年)(\d{2})(月)(\d{2})(日)$/;
          if(!invDoInvDate.test(value)){
            return callback( new Error("日期格式为XXXX年XX月XX日") )
          }
        } else {
            return callback( new Error("请输入发票号码") )
          }
        callback()
      }
    /* 项目名称校验 */
    const checkProductName = (rule, value, callback) => {
        if(!value) {
          tableCheckTipHandle('项目名称')
          return callback( new Error("请输入项目名称") )
        }
        deleteCheckTipHandle('"项目名称"')
        callback()
      }
    /* 数量校验 */
    const checkNumber = (rule, value, callback) => {
        if(value!=''&& value != null) {
          const Reg = /^(-)?(([1-9]{1}\d*)|(0{1}))(\.\d{1,12})?$/
          if(!Reg.test(value)){
            tableCheckTipHandle('数量')
            return callback( new Error("最大支持12位小数") )
          }
        }
        // else {
        //   tableCheckTipHandle('数量')
        //     return callback( new Error("请输入数量") )
        //   }
        deleteCheckTipHandle('"数量"')
        callback()
      }
    /* 单价校验 */
    const checkNnitPrice = (rule, value, callback) => {
        if(value) {
          const Reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,10})?$/
          if(!Reg.test(value)){
            tableCheckTipHandle('单价')
            return callback( new Error("最大支持10位小数") )
          }
        }
        // else {
        //   tableCheckTipHandle('单价')
        //     return callback( new Error("请输入单价") )
        //   }
          deleteCheckTipHandle('"单价"')
        callback()
      }
    /* 金额校验 */
    const checkMoney = (rule, value, callback) => {
        if(value === '0') {
          tableCheckTipHandle('金额')
          return callback( new Error("金额不能为0") )
        } else if(!value){
          tableCheckTipHandle('金额')
          return callback( new Error("请输入金额") )
        } else if(value !== "0"){
          const Reg = /^(-)?(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
          if(!Reg.test(value)){
            tableCheckTipHandle('金额')
            return callback( new Error("最大支持2位小数") )
          }
        }
        deleteCheckTipHandle('"金额"')
        callback()
      }
    /* 税率校验 */
    const checkTaxRate = (rule, value, callback) => {
        if(value !== '') {
          if(value =='免税'){
            callback()
          }else{
            const Reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?[%]{1}$/
            if(value!='0'&&!Reg.test(value)){
              tableCheckTipHandle('税率')
              return callback( new Error("请输入带%的数字或'免税'") )
            }
          }
        } else {
          tableCheckTipHandle('税率')
          return callback( new Error("请输入税率") )
          }
          deleteCheckTipHandle('"税率"')
        callback()
      }
    /* 税额校验 */
    const checkTaxMoney = (rule, value, callback) => {
        if(value !== '') {
          const Reg = /^(-)?(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
          if(value!='***'&&value!=='0'&&!Reg.test(value)){
            tableCheckTipHandle('税额')
            return callback( new Error("请输入不超过两位小数的数字或'***'") )
          }
        } else {
          tableCheckTipHandle('税额')
            return callback( new Error("请输入税额") )
          }
          deleteCheckTipHandle('"税额"')
        callback()
      }
      /* 销售方纳税人识别号 */
      const checkTaxId = (rule, value, callback) => {
        if(value =='') {
          return callback(new Error("请输入纳税人识别号"))
        }else if(value.length == 15 || value.length == 17 ||value.length == 18 || value.length == 20){
          const pattern = /^[A-Z0-9]+$/
          if(!pattern.test(value)){
            return callback(new Error("纳税人识别号只能输入数字或大写英文字母"))
          }
          callback()
        }else{
          return callback(new Error("请检查纳税人识别号"))
        }
      }
      /* 购买方纳税人识别号 */
      const buyerCheckTaxId = (rule, value, callback) => {
        if(value.length == 15 || value.length == 17 ||value.length == 18 || value.length == 20){
          const pattern = /^[A-Z0-9]+$/
          if(!pattern.test(value)){
            return callback(new Error("纳税人识别号只能输入数字或大写英文字母"))
          }
          callback()
        }else if(value.length){
          return callback(new Error("请检查纳税人识别号"))
        }
        callback()
      }
    /* 价税合计校验 */
    const checkTotalPriceTax = (rule, value, callback) => {
        if(value) {
          const Reg = /^(-)?(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
          if(value!='***'&&!Reg.test(value)){
            tableCheckTipHandle('价税合计')
            return callback( new Error("最大支持2位小数") )
          }
        } else {
          tableCheckTipHandle('价税合计')
            return callback( new Error("请输入价税合计") )
          }
        callback()
      }
        return {
            loading:false,
            queryForm: {
            },
            invoiceInfo: {
            // 基本信息
            id: null,
            invHeader: null,
            invCode: null,
            invNumber: null,
            doInvDate: null,
            checkCode: null,
            machineCode: null,
            //购买方信息
            buyerName: null,
            buyerTaxpayerNum: null,
            buyerPhoneNum: null,
            buyerBankCardNum: null,
            buyerPassword: null,
            //销售方信息
            sellName: null,
            sellTaxpayerNum: null,
            sellPhoneNum: null,
            sellBankCardNum: null,
            salerType:'',
            postscript: null,
            payee: null,  //收款人
            review: null, // 复查
            drawer: null, //开票人
            fileUrl:null,
            fileName:null,
            // 项目表格
            projectForm: [
              {
                productName:null,
                productUnit:null,
                unitName:null,
                count:null,
                unitPrice:null,
                invoiceAmount:null,
                taxRate:null,
                taxAmount:null,
            }
            ],
            totalMoney:null,
            totalTaxMoney:null,
            totalPriceTaxDX:null,
            totalPriceTax:null,
            },
            invoiceInfoRules:{
                //静态表单校验
                invHeader:[ {required: true,validator: checkInvHeader, trigger: 'change'}],
                invCode:[ { required: true, validator: checkInvCode, trigger: 'change' }],
                invNumber:[ { required: true, validator: checkInvNumber, trigger: 'change' }],
                doInvDate:[ { required: true, validator: checkDoInvDate, trigger: 'change' }],
                buyerName:[ { required: true, message: '请输入购买方名称', trigger: 'change' }],
                buyerPassword:[ { required: true, message: '请输入密码区内容', trigger: 'change' }],
                sellName:[ { required: true, message: '请输入销售方名称', trigger: 'change' }],
                sellTaxpayerNum:[ { required: true, validator: checkTaxId, trigger: 'change' }],
                buyerTaxpayerNum:[ {  validator: buyerCheckTaxId, trigger: 'change' }],
                totalPriceTax:[ {required: true,validator: checkTotalPriceTax, trigger: 'change'}],
                checkCode:[ {required: true,min:20,max:20,message:'校验码长度为20', trigger: 'change'} ],

                /* 动态表单校验 */
                productName:[ {  required: true,validator: checkProductName, trigger: 'change' }],
                count:[ {  validator: checkNumber, trigger: 'change' }],
                unitPrice:[ { validator: checkNnitPrice, trigger: 'change' }],
                invoiceAmount:[ { required: true, validator: checkMoney, trigger: 'change' }],
                totalMoney:[ { required: true, validator: checkMoney, trigger: 'change' }],//合计金额
                taxRate:[ { required: true, validator: checkTaxRate, trigger: 'change' }],
                taxAmount:[ { required: true, validator: checkTaxMoney, trigger: 'change' }],
                totalTaxMoney:[ { required: true, validator: checkTaxMoney, trigger: 'change' }],//合计税额
            },
            /*  */
            ab:null,
            domObj:null,
            showBox:false,
            tranLeft:'400px',
            tranTop:'400px',
            idList:['b_01','b_02','b_03','b_04','b_05','b_06','b_11','b_12','b_13','b_14','b_15','b_21','b_22','b_23','b_24','b_25','b_26','b_27','b_28',],
            visible:true,
            disabledForward:false,
            disabledackwards:false,
            invoiceImageUrl:'',
            currentPageCount:1,
            total:0,
            unCheckCount: 0,  //非图片+图片不需要校验总数
            needCheckCount:0, //需要校验数量
            needCheckIds:[], //所有需要校验的id
            currentPageInvoiceId:'',//当前页面发票信息的 id
            isClickLeft:false,
            isClickRight:false,
            tableCheckTip:'',//底部表格未校验通过的字段集合
            labelPosition:'left',
            isSubmit:false,
            isdeling:true,// 图片的显示状态
    };
    },
    created(){
    },
    mounted() {
      // let visible =this.$route.query.visible;
      //更新刷新次数缓存，处理页面刷新不能回退的问题
      var userId  = this.getToken("userId");
      var refreshTime = this.getToken("refreshTime"+userId);
      if(refreshTime){
        this.setToken("refreshTime"+userId,++refreshTime);
      }else{
        this.setToken("refreshTime"+userId,1);
      }
      let visible =this.getToken('uploadListShow');
      if(visible=='true'){
        this.visible = true
        this.getList()
      }else{
        this.visible =false

      }
        // this.scrollFunction()
        setTimeout(()=> {
            this.scrollFunction()
          }, 100)
    },

    methods: {
      // 删除本张发票
      deleteInvoiceHandle(){
        let  _this =this;
        //删除最后一张
        if(this.currentPageCount==this.needCheckCount&&this.currentPageCount==1 && this.unCheckCount == 0){
          einvAlert.confirm(function (){_this.deleteInvoiceConfirm(1)},"删除最后一张发票后，本次导入任务 默认取消，请确认是否继续删除？","确认","取消",function (){
          });
        }else{
          einvAlert.confirm(function (){_this.deleteInvoiceConfirm(0)},"删除后该发票将从本次导入任务中取消","确认","取消",function (){
          });
        }
      },
      deleteInvoiceConfirm(isLastOne){
        let _this=this;
        _this.$store.dispatch('PostDeleteImage',{
          clientId:_this.getToken('preUploadClientId'),
          invoiceId:_this.invoiceInfo.id
        }).then(res => {
          _this.needCheckIds.splice(_this.currentPageCount-1,1);
          if(_this.currentPageCount==_this.needCheckCount){
            _this.currentPageCount--
          }
          _this.needCheckCount--;
          if(isLastOne){

            if(this.getToken('uploadType')){
              setTimeout(function (){
                  //定义代理记账 上传类型 --> 纸票删除 iframe通讯
                  window.parent.postMessage("导入成功", '*');
              },500);
            }else{
              _this.cancelTask();
              _this.visible=false;
              this.setToken('uploadListShow',false)
              if(_this.$route.path=='/income/invoiceAccount/invoiceInfo'){
                _this.$router.push('/income/invoiceAccount');
              }else{
                _this.$router.push('/writeOff/invoiceAccount');
              }
            }
          }else{
            einvAlert.success("提示",'删除成功')
            if(_this.currentPageCount<=0){
              _this.visible = false
              this.setToken('uploadListShow',false)
            }else{
              _this.currentPageInvoiceId=_this.needCheckIds[_this.currentPageCount-1];
            _this.getList(_this.currentPageInvoiceId)
            }
          }

        }).catch(err =>{
          err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')
        })
      },
      cancelTask(){
        /* 调清除上次数据接口 */
        this.$store.dispatch('PostCancelUpload',{
          clientId:this.getToken('preUploadClientId')
        }).then(res =>{
          this.removeItemToken('preUploadClientId');
        }).catch(err =>{

        })
        this.importVis = true;
      },
      isImgOnload(){
        this.isdeling = false
        console.log(this.isdeling)
      },
        //向左翻页
        backwards(){
          this.isClickLeft = true
          this.isSubmit =false;
          this.$refs.submitForm.handleClick();
        },
        //向右翻页
        forward(){
          this.isClickRight =true
          this.isSubmit =false;
          this.$refs.submitForm.handleClick() ;
        },
        /* 提交按钮，做表单二次校验 */
        submitForm(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
              this.uploadInvoiceInfoHandle()
            } else {
                 einvAlert.error("提示",'有字段未校验成功，请先完成校验！')
                return false;
            }
            });
        },
        //表单信息提交的请求
        uploadInvoiceInfoHandle(){
          console.log('发送提交表单信息的请求了')
          let _this=this;
          // let projectForm=this.invoiceInfo.projectForm.map(item =>{
          //   if(item.taxRate!='免税'){
          //     item.taxRate = item.taxRate / 100
          //     return item
          //   } else {
          //     return item
          //   }
          // });
            this.$store.dispatch('PostCheckInfo',{
                clientId:this.getToken('preUploadClientId'),
                id:this.invoiceInfo.id,//需要上传的id
                title:this.invoiceInfo.invHeader,
                invoiceCode:this.invoiceInfo.invCode,
                invoiceNo:this.invoiceInfo.invNumber,
                taxAmount:this.invoiceInfo.totalTaxMoney,//税额
                buyerName:this.invoiceInfo.buyerName,//购买方名称
                buyerTaxId:this.invoiceInfo.buyerTaxpayerNum,
                buyerAddressPhone:this.invoiceInfo.buyerPhoneNum,
                buyerBankNum:this.invoiceInfo.buyerBankCardNum,
                sellerName:this.invoiceInfo.sellName,//销售方名称
                sellerTaxId:this.invoiceInfo.sellTaxpayerNum,
                sellerAddressPhone:this.invoiceInfo.sellPhoneNum,
                sellerBankNum:this.invoiceInfo.sellBankCardNum,
                validateCode:this.invoiceInfo.checkCode,//校验码
                mechineNum:this.invoiceInfo.machineCode,//机器码
                passwordField:encodeURIComponent(this.invoiceInfo.buyerPassword),//密码区
                remark:this.invoiceInfo.postscript,
                receiver:this.invoiceInfo.payee,//收款人
                reviewer:this.invoiceInfo.review,// 复查
                drawer:this.invoiceInfo.drawer,//开票人
                saler:'',//销售方 ，不必须
                salerType: this.invoiceInfo.salerType,
                amountInWords:this.invoiceInfo.totalPriceTaxDX,//金额的大写
                invoiceTime:this.invoiceInfo.doInvDate,//开票时间
                invoiceAmount:this.invoiceInfo.totalPriceTax,
                newDetailList:encodeURIComponent(JSON.stringify(this.invoiceInfo.projectForm)),//明细列表,
                fileUrl:this.invoiceInfo.fileUrl,
                fileName:this.invoiceInfo.fileName,
                originalFileName:this.invoiceInfo.originalFileName
            }).then(res => {
              if(res.success){
                //向左翻页
                if(this.isClickLeft){
                  if(this.currentPageCount>1){
                    this.currentPageCount--;
                    this.currentPageInvoiceId=this.needCheckIds[this.currentPageCount-1];
                  }
                  this.isClickLeft = false;
                }
                //向右翻页
                if(this.isClickRight){
                  if(this.currentPageCount<this.needCheckIds.length){
                    this.currentPageCount++;
                    this.currentPageInvoiceId=this.needCheckIds[this.currentPageCount-1];
                  }
                  this.isClickRight = false;
                }
                //最后一页，确认提交
                if(this.isSubmit){
                  this.visible= false
                  this.setToken('uploadListShow',false)
                  // console.log('跳转到第二个页面')
                }else{
                  _this.getList(this.currentPageInvoiceId);
                }
              } else {
                 einvAlert.error("提示",res.msg||res.message)
              }
            }).catch(err =>{
              err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')
            })
        },
        RemInputHandle(){
            // this.showBox = false
        },
        //输入框聚焦时
        focusHandle(id){
            // console.log(this.$refs[id].style);
            console.log(this.idList);
            this.idList.forEach(item =>{
                this.$refs[item].style.opacity= 0
                this.$refs[item].style.visibility= 'hidden'
            })
            this.$refs[id].style.opacity= 1
            this.$refs[id].style.visibility= 'visible'
            console.log(id,'输入框id');
            // console.log(this.$refs[id]);
        },
        //输入框失去焦点时
        blurHandle(id){
            console.log('失去焦点');

        },
        // 点击区域事件委托
        RemInputEntrust(e,state){
            console.log('输入框冒泡了');
            if(e.target.dataset.index=='b_00') return false
            this.idList.forEach(item =>{
                this.$refs[item].style.opacity= 0
                this.$refs[item].style.visibility= 'hidden'
            })
            let id = e.target.dataset.index
            console.log(id,'idd')

            this.$refs[id].style.opacity= 1
            this.$refs[id].style.visibility= 'visible'
        },
        /* 确定导入按钮 */
        confirmImport(){
            // 先提交最后一页数据，在做最后的确认都如
          this.isSubmit =true;
          this.$refs.submitForm.handleClick()
        },
        scrollFunction () {
            this.domObj = document.getElementById('projectFormWrap') // 通过id获取要设置的div
          if(this.domObj){
            if (this.domObj.attachEvent) { // IE
              this.domObj.attachEvent('onmousewheel', this.mouseScroll)
            } else if (this.domObj.addEventListener) {
              this.domObj.addEventListener('DOMMouseScroll', this.mouseScroll, false)
            }
            this.domObj.onmousewheel = this.domObj.onmousewheel = this.mouseScroll
          }

        },
        mouseScroll(event) { // google 浏览器下
            let detail = event.wheelDelta || event.detail
            let moveForwardStep = -1
            let moveBackStep = 1
            let step = 0
            step = detail > 0 ? moveForwardStep * 100 : moveBackStep * 100
            event.preventDefault() // 阻止浏览器默认事件
            this.domObj.scrollLeft = this.domObj.scrollLeft + step
        } ,
        // 金额小写转大写
        cnMoneyFormat(money) {
            var cnMoney = "零元整";
            var strOutput = "";
            var strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
            money += "00";
            var intPos = money.indexOf('.');
            if (intPos >= 0) {
                money = money.substring(0, intPos) + money.substr(intPos + 1, 2);
            }
            strUnit = strUnit.substr(strUnit.length - money.length);
            for (var i = 0; i < money.length; i++) {
                strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(money.substr(i, 1), 1) + strUnit.substr(i, 1);
            }
            cnMoney = strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元");
            return cnMoney;
        },
        // 添加商品信息行
        addProjectHandle(){
            let objProjectRow ={
                productName:null,
                productUnit:null,
                unitName:null,
                count:null,
                unitPrice:null,
                invoiceAmount:null,
                taxRate:null,
                taxAmount:null,
            }
            this.invoiceInfo.projectForm.push(objProjectRow)
        },
        // 删除行
        delProjectRow(index){
            if(this.invoiceInfo.projectForm.length<=1) return
            this.invoiceInfo.projectForm.splice(index,1)
        },

        /* 获取列表信息 */
        getList(invoiceId) {
          this.isdeling = true
          this.invoiceImageUrl =''
          let _this=this;
          _this.loading=true;
            // this.isLoading = true
            /* 获取列表信息 */
            this.$store.dispatch("GetCurrentInvoiceInfo", {
              clientId:_this.getToken('preUploadClientId'),
              invoiceId:invoiceId
            }).then(res => {
              _this.loading=false;
              if (res.success) {
                /* 列表信息全在这 */
                //   this.isLoading = false
                let info = res.data
                _this.invoiceInfo.id= info.id
                _this.invoiceInfo.invHeader= info.title
                _this.invoiceInfo.invCode= info.invoiceCode
                _this.invoiceInfo.invNumber= info.invoiceNo
                _this.invoiceInfo.doInvDate= info.invoiceTime
                _this.invoiceInfo.checkCode= info.validateCode
                _this.invoiceInfo.machineCode= info.mechineNum
                //购买方信息
                _this.invoiceInfo.buyerName= info.buyerName
                _this.invoiceInfo.buyerTaxpayerNum= info.buyerTaxId
                _this.invoiceInfo.buyerPhoneNum= info.buyerAddressPhone
                _this.invoiceInfo.buyerBankCardNum= info.buyerBankNum
                _this.invoiceInfo.buyerPassword= info.passwordField
                //销售方信息
                _this.invoiceInfo.sellName= info.sellerName
                _this.invoiceInfo.sellTaxpayerNum= info.sellerTaxId
                _this.invoiceInfo.sellPhoneNum= info.sellerAddressPhone
                _this.invoiceInfo.sellBankCardNum= info.sellerBankNum
                _this.invoiceInfo.salerType= info.salerType
                _this.invoiceInfo.postscript= info.remark
                _this.invoiceInfo.payee= info.receiver  //收款人
                _this.invoiceInfo.review= info.reviewer // 复查
                _this.invoiceInfo.drawer= info.drawer //开票人
                // _this.invoiceInfo.totalMoney=info.excludeTaxAmount  //金额(jia)
                if(info.taxAmount!=='***'){
                  _this.invoiceInfo.totalMoney = (info.invoiceAmount - info.taxAmount).toFixed(2)
                } else {
                  _this.invoiceInfo.totalMoney = info.invoiceAmount
                }
                _this.invoiceInfo.totalTaxMoney=info.taxAmount,
                _this.invoiceInfo.totalPriceTaxDX=info.amountInWords
                _this.invoiceInfo.totalPriceTax=info.invoiceAmount
                _this.invoiceInfo.fileUrl=info.fileUrl
                _this.invoiceInfo.fileName=info.fileName
                _this.invoiceImageUrl= process.env.VUE_APP_BASE_URL+'/api/einvoice/file/'+info.fileUrl+'?Token='+this.getCookies('Authorization')+"&Group="+process.env.VUE_APP_GROUP,
                _this.total=info.total,
                _this.invoiceInfo.originalFileName=info.originalFileName
                _this.unCheckCount = info.unCheckCount
                if(_this.needCheckIds.length==0){
                  _this.needCheckIds=info.needCheckIds;
                }
                if(_this.needCheckCount==0){
                  _this.needCheckCount=info.needCheckCount
                }
                _this.invoiceInfo.projectForm=info.detailList.map(item =>{
                  if(item.taxRate==''||item.taxRate=='0.0'||item.taxRate=='0.00'){
                    item.taxRate = '免税'
                  }
                  if(item.taxAmount==''||item.taxAmount=='0.0'||item.taxAmount=='0.00'){
                    item.taxAmount = '***'
                  }
                  return item
                })


                if(_this.currentPageCount==1){
                  _this.disabledackwards =true
                }else{
                  _this.disabledackwards =false
                }
                if(_this.currentPageCount==_this.needCheckCount){
                  _this.disabledForward =true
                }else{
                  _this.disabledForward =false
                }
                // this.isdeling = true
              } else {
                einvAlert.error("提示",res.msg)
                this.$router.back()
            }
            }).catch(err => {
            })

        },
    },
    computed:{

    },
    watch:{
    'invoiceInfo.totalPriceTax': {
      handler(value){
        this.invoiceInfo.totalPriceTaxDX = this.cnMoneyFormat(value)
      },
      immediate:true
    }
    },
    beforeDestroy(){
        if (!this.domObj) return
        if (this.domObj.attachEvent) {
        this.domObj.detachEvent('onmousewheel', this.mouseScroll)
        }
        if (this.domObj.addEventListener) {
        this.domObj.removeEventListener('DOMMouseScroll', this.mouseScroll, false)
        }

    },

};
</script>
<style lang="less">
 .important_image_edit{
   .el-form-item__error {
     z-index: 1000;
   }
   textarea{

   }
 }
</style>
<style lang="less" scoped>
  /deep/.lower {
    .el-form-item__content {
      width: 75%!important;
    }
  }
.textarea {
  /deep/ .el-textarea__inner {
    resize:none;

  }
}
.box-card-tip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 1564px;
  width: 99.5%;
  height: 60px;
  padding: 22px;
  margin:0 40px 22px 0;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  background-color: #fff;
  .iconicon-13 {
    color: rgba(255, 151, 0);
    padding-right: 6px;
  }
  .download {
  width: 100px;
  height: 40px;
  padding: 0;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  }
}
.contain_left {
  float: left;
  height: 91vh;
  margin-right: 24px;
  width: 60%;
  overflow: scroll;
}
.placeholderBox {
  width: 100%;
  min-height: 50vh;
}
.contain_right {
  float: left;
  height: 91vh;
  width: 38%;
  overflow: scroll;
}
.box-card-left{
  margin-right: 24px;
  // width: 60%;
  width: 100%;
  // min-height: 1370px;
  height: 100%;
}
.box-card-right {
  // width: 38%;
  width: 100%;
  min-height: 1370px;
}
.box-card-left,.box-card-right{
  float: left;
  background-color: #fff;
  padding:20px 20px 80px;
  .compNav {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 24px;
    font-size: 16px;
    .compNavIcon {
        width: 4px;
        height: 16px;
        margin-right: 12px;
        background-color: #409EFF;
        border-radius: 50em;
    }
  }
}

.el-col-12 {
    margin: 0;
}

/deep/.form-inline {
  .double_query {
    .el-form-item__content {
    width: 64% !important;
    margin-right:0 !important;
    }
  }
  .double_query_b {
    .el-form-item__content {
    width: 59% !important;
    margin-right:0 !important;
    }
  }
  .double_query_c {
    .el-form-item__content {
    width: 72% !important;
    margin-right:0 !important;
    }
  }
  .single_query {
    .el-form-item__content {
    width: 77% !important;
    margin-right:0 !important;
    }
  }
}
.compNavT {
  padding: 24px 0;
  font-size: 16px;
}
.invoice-Template {
  position:sticky;
  top: 0;
  z-index: -9;
}
.projectFormWrap {
  width: 100%;
  // height: 100px;
  overflow: scroll;
}

.projectForm {
  width: 900px;
  font-size: 14px;
  // text-align: center;
  border: 1px solid #d1d1d1;
  padding: 12px;
}
 .projectFormWrap::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
  }
.sideBlank {
  width: 26px;
  height: 10px;
}
.tableCheckTip{
  color: red;
  letter-spacing: 2px;
  padding-top: 14px;
}
.mustCheckout {
  color: red;
}
.projectFormTitle {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #d1d1d1;

}
.control {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 24px;
}
.f24{
  font-size: 22px;
  padding: 7px;
  margin: 0 10px;
}

.colItem {
  /deep/.el-form-item{
  width: 100%;
  margin: 0;
  }
  .iconjia {
    color: #36c6b4;
  }
}
.el-col-6 {
  width: 28%;
}
.taxRate {
  // .el-col-2 {
    width: 9.5%;
  // }
}


/* ——————发票区域标注—————— */
//  .invoiceImgWrap {
//   // position: relative;
//   position: sticky;
//   top: 60px;
//   width: 100%;
// }

.b_01 {
    position: absolute;
    top: 17px;
    left: 224px;
    width: 286px;
    height: 48px;
}
.b_02 {
    position: absolute;
    top: 37px;
    left: 516px;
    width: 179px;
    height: 16px;
    color: #121212;

}
.b_03 {
    position: absolute;
    top: 55px;
    left: 516px;
    width: 179px;
    height: 16px;
    color: #121212;
}
.b_04 {
    position: absolute;
    top: 74px;
    left: 516px;
    width: 179px;
    height: 16px;
    color: #121212;
}
.b_05 {
    position: absolute;
    top: 90px;
    left: 516px;
    width: 179px;
    height: 16px;
    color: #121212;
}
.b_06 {
    position: absolute;
    top: 80px;
    left: 30px;
    width: 134px;
    height: 24px;
    color: #121212;
}
.b_11 {
    position: absolute;
    top: 105px;
    left: 62px;
    width: 360px;
    height: 17px;
    color: #121212;
}
.b_12 {
    position: absolute;
    top: 123px;
    left: 62px;
    width: 360px;
    height: 17px;
    color: #121212;
}
.b_13 {
    position: absolute;
    top: 141px;
    left: 62px;
    width: 360px;
    height: 17px;
    color: #121212;
}
.b_14 {
    position: absolute;
    top: 159px;
    left: 62px;
    width: 360px;
    height: 17px;
    color: #121212;
}
.b_15 {
    position: absolute;
    top: 106px;
    left: 442px;
    width: 254px;
    height: 69px;
    color: #121212;
    border: 1px solid #000;
}
.b_21 {
    position: absolute;
    top: 365px;
    left: 55px;
    width: 360px;
    height: 16px;
    color: #121212;
}
.b_22 {
    position: absolute;
    top: 382px;
    left: 55px;
    width: 360px;
    height: 16px;
    color: #121212;
}
.b_23 {
    position: absolute;
    top: 400px;
    left: 55px;
    width: 360px;
    height: 16px;
    color: #121212;
}
.b_24 {
    position: absolute;
    top: 417px;
    left: 55px;
    width: 360px;
    height: 16px;
    color: #121212;
}
.b_25 {
    position: absolute;
    top: 367px;
    left: 442px;
    width: 258px;
    height: 66px;
    color: #121212;
}
.b_26 {
    position: absolute;
    top: 436px;
    left: 40px;
    width: 120px;
    height: 24px;
    color: #121212;
}
.b_27 {
    position: absolute;
    top: 436px;
    left: 216px;
    width: 120px;
    height: 24px;
    color: #121212;
}
.b_28 {
    position: absolute;
    top: 436px;
    left: 370px;
    width: 120px;
    height: 24px;
    color: #121212;
}

</style>

